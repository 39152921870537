import * as React from "react"

import { Container, Heading, Paragraph } from "../components/elements/ui"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <Heading is="h1">404: Not Found</Heading>
      <Paragraph>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Paragraph>
    </Container>
  </Layout>
)

export default NotFoundPage
